import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import style from "../assets/bg.module.css";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";

const FormRegister = () => {
  const [isLoading, setLoading] = useState("Buat");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [message, setMessage] = useState("");

  const [hidePass, setHidePass] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  const handleHide = () => {
    if (hidePass === "password") {
      setIcon(eye);
      setHidePass("text");
    } else {
      setIcon(eyeOff);
      setHidePass("password");
    }
  };

  // const getUsername = async () => {
  //   const response = await axios.get(
  //     "https://tame-gold-barnacle-tie.cyclic.app/users"
  //   );
  //   setUsername(response.data);
  // };

  function handleClick() {
    setLoading("Loading...");
  }

  const saveUser = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://tame-gold-barnacle-tie.cyclic.app/users", {
        name: name,
        email: email,
        password: password,
        confirmPassword: passwordConfirm,
        role: "user",
        isHaveMessage: 0,
      });
      navigate("/login");
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
        setLoading("Buat");
      }
    }
  };
  return (
    <div>
      <div className={style.box}>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0  relative z-10">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 bg-opacity-60 dark:bg-opacity-60">
            <div className="p-6 space-y-4 md:space-y-4 sm:p-8">
              <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Secret App
              </h1>
              <h1 className="text-xl  text-center leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Buat Akun
              </h1>
              <form onSubmit={saveUser} className="space-y-4 md:space-y-6">
                <p className="text-red-700 font-bold text-center">{message}</p>

                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Username"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email Anda
                  </label>
                  <p className="text-gray-400 text-sm mb-2">
                    Note: Boleh menggunakan email asal
                  </p>
                  <input
                    type="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="relative block">
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>

                  <input
                    type={hidePass}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="**********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    onClick={handleHide}
                    className="absolute inset-y-0 right-0 flex items-center pt-6 pr-3 text-black dark:text-white"
                  >
                    <Icon icon={icon} size={25}></Icon>
                  </span>
                </div>
                <div className="relative block">
                  <label
                    htmlFor="Confirm password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Konfirmasi Password
                  </label>
                  <input
                    type={hidePass}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="**********"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  onClick={handleClick}
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  {isLoading}
                </button>
              </form>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Sudah Mempunyai Akun?{" "}
                <Link
                  to={"/login"}
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FormRegister;
