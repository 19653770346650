import React, { useState, useEffect } from "react";
import UserPesan from "../components/UserPesan";
import LayoutMain from "./LayoutMain";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const UserPesanSaya = () => {
  const [data, setData] = useState([]);
  const [dapat, setDapat] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const getUd = async () => {
    const res = await axios.get(
      `https://tame-gold-barnacle-tie.cyclic.app/header-name/${id}`
    );
    setData(res.data);
    setDapat(true);
  };

  useEffect(() => {
    getUd();
  }, []);

  useEffect(() => {
    cari();
  }, [data]);

  const cari = () => {
    if (dapat) {
      if (data?.nama === id) {
      } else {
        navigate("/error");
      }
    }
  };

  return (
    <div className="justify-center mx-auto px-4 lg:max-w-7xl md:items-center md:flex md:px-8">
      <UserPesan />
    </div>
  );
};

export default UserPesanSaya;
