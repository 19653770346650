import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import PaginationPublic from "./Pagination/PaginationPublic";
import { useSelector } from "react-redux";
import style from "../assets/bg.module.css";
import Linkify from "react-linkify";
import Banner from "./Banner/Banner480x60";

const UserPesan = () => {
  const [isLoading, setLoading] = useState(true);
  const [isLoadingKomen, setLoadingKomen] = useState(true);
  const [clickedButton, setClickedButton] = useState(0);
  const [animLoad, setAnimLoad] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(10);

  const [text, setTanggapan] = useState("");
  const [name, setName] = useState("null");

  const [MyPesan, setTampilPesan] = useState([]);
  const [komenPesan, setTampilKomenPesan] = useState([]);
  const [pesanId, setIdPesan] = useState("");
  const [UUID, setUUID] = useState("");
  const [Token, setToken] = useState("");

  const [textKomen, setKomen] = useState("");

  const [IdUrl, setIdUrl] = useState("");

  const [header, setHeader] = useState("");
  const [pertanyaan, setPertanyaan] = useState("");
  const [hideHeader, setHideHeader] = useState(false);

  const [idUser, setIdUser] = useState("");
  const { id } = useParams();

  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    getKomenPesan();
    getDataHeader();
  }, []);

  useEffect(() => {
    if (idUser) {
      getPesanById();
      getUserById();
    }
  }, [idUser]);

  useEffect(() => {
    if (pertanyaan?.length > 0) {
      setHideHeader(true);
    }
    if (pertanyaan?.length === 0) {
      setHideHeader(false);
    }
    if (user) {
      let nama = user && user.name;
      setName(nama);
    }
  }, [pertanyaan, user, UUID]);

  function handleClick() {
    if (!text.length == 0) {
      setLoading(false);
    }
    if (!textKomen.length == 0) {
      setLoadingKomen(false);
    }
  }

  const savePesan = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://tame-gold-barnacle-tie.cyclic.app/pesan-saya", {
        uuid: IdUrl,
        name: name,
        text: text,
        is_read: 0,
        token: Token,
      });
      setLoading(true);
      setTanggapan("");
      navigate("/send");
    } catch (error) {
      console.log(error);
    }
  };

  const saveKomen = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        "https://tame-gold-barnacle-tie.cyclic.app/komentar-pesan",
        {
          uuid: UUID,
          name: name,
          text: textKomen,
          pesanId: pesanId,
        }
      );
      setKomen("");
      setLoadingKomen(true);
      e.target.reset();
      getKomenPesan();
      // window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  function handleChange(event) {
    event.preventDefault();
    let temp = event.target.value;
    setKomen(temp);
  }

  const onGetId = (id) => {
    setIdPesan(id);
  };
  const onGetuuid = (uuid) => {
    setUUID(uuid);
  };

  const getPesanById = async () => {
    const response = await axios.get(
      `https://tame-gold-barnacle-tie.cyclic.app/pesan-saya/${idUser}`
    );

    setTampilPesan(response.data);
    setAnimLoad(false);
  };

  const getUserById = async () => {
    const response = await axios.get(
      `https://tame-gold-barnacle-tie.cyclic.app/tokens/${idUser}`
    );

    setToken(response.data.token);
  };

  const getKomenPesan = async () => {
    const response = await axios.get(
      `https://tame-gold-barnacle-tie.cyclic.app/komentar-pesan`
    );
    setTampilKomenPesan(response.data);
  };

  const getDataHeader = async () => {
    const response = await axios.get(
      `https://tame-gold-barnacle-tie.cyclic.app/header-name/${id}`
    );
    setIdUrl(response.data.uuid);
    setHeader(response.data.nama);
    setIdUser(response.data.uuid);
    setPertanyaan(response.data.pertanyaan);
  };

  const indexLastPost = currentPage * postPerPage;
  const indexFirstPost = indexLastPost - postPerPage;
  const currentPost = MyPesan.slice(indexFirstPost, indexLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} className="text-blue-900" target="_blank">
      {text}
    </a>
  );

  return (
    <div>
      <div className={style.box}>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <section className="py-8 mb-8 lg:py-16 relative z-10">
        <div className="max-w-2xl mx-auto px-4">
          <div className=" items-center mb-3">
            <h2 className="text-center text-lg lg:text-2xl text-gray-900 dark:text-gray-200">
              Berikan Pesan Rahasia Anda Kepada
            </h2>
            <h2 className="text-center text-lg lg:text-2xl font-bold text-gray-900 dark:text-gray-200">
              {header}
            </h2>
          </div>
          <form className="mb-6" onSubmit={savePesan}>
            {/* <h1 className="text-center text-red-500">{error}</h1> */}
            {hideHeader ? (
              <p className="pb-2 text-sm font-medium text-gray-900 text-center antialiased dark:text-gray-400">
                {/* <span className="font-medium antialiased">Pertanyaan? : </span> */}
                {pertanyaan}
              </p>
            ) : (
              <div></div>
            )}
            <p className="pb-2 pl-2 text-sm text-gray-900 dark:text-gray-400">
              {header} tidak akan pernah tahu siapa yang mengirim pesan ini!
            </p>
            <div className="py-2 px-4 mb-4 bg-gray-200 rounded-lg rounded-t-lg border bg-opacity-75 border-gray-200  dark:bg-opacity-75  dark:bg-gray-800 dark:border-gray-700">
              <label className="sr-only">Your comment</label>
              <textarea
                id="comment"
                rows="6"
                className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none bg-gray-300 bg-opacity-5 dark:text-white  dark:bg-opacity-5 placeholder-gray-900 dark:placeholder-gray-400 dark:bg-gray-800"
                placeholder="Tulis Komentar..."
                value={text}
                onChange={(e) => setTanggapan(e.target.value)}
                required
              ></textarea>
            </div>

            <button
              type="submit"
              onClick={handleClick}
              className="w-full  items-center py-3.5 px-4 text-xs font-medium text-center text-white bg-primary-500 dark:bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
            >
              {!isLoading ? (
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              ) : (
                ""
              )}
              {isLoading ? "Kirim Pesan" : "Loading..."}
            </button>
          </form>

          {/* <div className="text-gray-900 dark:text-gray-200 text-2xl pt-4 pl-2 pb-4 mb-3">
            Timeline Of <a className="font-bold">{header}</a>
          </div>
          <div className="flex justify-center mb-6 mt-3">
            <PaginationPublic
              postPerPage={postPerPage}
              totalPost={MyPesan.length}
              paginate={paginate}
            />
          </div> */}

          {/* {animLoad ? (
            <div role="status" className="max-w animate-pulse">
              <article
                className=" mb-6 text-base bg-white rounded-lg dark:bg-gray-900 box-border w-full p-4 
                border-2 border-gray-600 border-rounded "
              >
                <Link className="text-gray-300 hover:text-gray-500">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[140px] mb-2.5"></div>
                </Link>

                <h1 className="text-gray-500 dark:text-gray-400 mb-3">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                </h1>

                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
              </article>
              <article
                className=" mb-6 text-base bg-white rounded-lg dark:bg-gray-900 box-border w-full p-4 
                border-2 border-gray-600 border-rounded "
              >
                <Link className="text-gray-300 hover:text-gray-500">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[140px] mb-2.5"></div>
                </Link>

                <h1 className="text-gray-500 dark:text-gray-400 mb-3">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                </h1>

                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
              </article>
              <article
                className=" mb-6 text-base bg-white rounded-lg dark:bg-gray-900 box-border w-full p-4 
                border-2 border-gray-600 border-rounded "
              >
                <Link className="text-gray-300 hover:text-gray-500">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[140px] mb-2.5"></div>
                </Link>

                <h1 className="text-gray-500 dark:text-gray-400 mb-3">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                </h1>

                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
              </article>
            </div> */}
          {/* ) : (
           currentPost.map((pes, index) => (
               <div
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                key={pes.id}
              >
                <div onClick={() => onGetuuid(pes.uuid)}>
                  <article
                    className=" mb-6 text-base bg-gray-200 rounded-lg dark:bg-gray-900 box-border h-auto w-full p-4 
                    border-2 dark:border-gray-600 border-rounded bg-opacity-75 dark:bg-opacity-80"
                    key={pes.id}
                    onClick={() => onGetId(pes.id)}
                  >
                    <Link
                      to={`${pes.id}`}
                      className="text-gray-800 dark:text-gray-300 hover:text-gray-500"
                    >
                      Lihat Detail
                    </Link>

                    <Linkify componentDecorator={componentDecorator}>
                      <p className="text-gray-800 text-clip break-words  dark:text-gray-400 mb-3">
                        {pes.text}
                      </p>
                    </Linkify>

                    <div
                      className="mb-3"
                      onClick={() => onGetId.bind(pes.id, pes.uuid)}
                    >
                      <form onSubmit={saveKomen}>
                        <div className="flex">
                          <div className="w-full  py-2 px-4 bg-black rounded-lg rounded-t-lg border border-gray-300 bg-opacity-5 dark:bg-opacity-5 dark:bg-gray-300 dark:border-gray-700">
                            <input
                              id="comment"
                              rows="6"
                              className=" px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none bg-white bg-opacity-5 dark:text-white dark:bg-opacity-5 placeholder-gray-900 dark:placeholder-gray-400 dark:bg-gray-800"
                              placeholder="Tulis Komentar..."
                              // value={text}
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                              required
                            />
                          </div>

                          <button
                            type="submit"
                            onClick={() => {
                              handleClick();
                              setClickedButton(index);
                            }}
                            key={index}
                            className="ml-4 py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-500 dark:bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
                          >
                            {!isLoadingKomen && clickedButton == index ? (
                              <svg
                                aria-hidden="true"
                                class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-400 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            ) : (
                              "Kirim"
                            )}
                          </button>
                        </div>
                      </form> */}
          {/* <AddKomenUser hey={pesanId} uD={UUID} /> */}
          {/* </div>
                    {komenPesan
                      .filter((dt) => dt.pesanId === pes.id)
                      .map((komentar) => (
                        <div data-aos="fade-down" key={komentar.id}>
                          <article className=" mb-2 text-base bg-gray-400 rounded-lg bg-opacity-30 dark:bg-opacity-50 dark:bg-gray-800">
                            <div className="bg-gray-900 bg-opacity-5 dark:bg-opacity-5 dark:bg-gray-200 rounded-lg w-full py-2.5 px-4 mb-2">
                              <Linkify componentDecorator={componentDecorator}>
                                <p className="text-gray-900 break-words dark:text-gray-400">
                                  {komentar.text}
                                </p>
                              </Linkify>
                            </div>
                          </article>
                        </div>
                      ))}
                  </article>
                </div>
              </div>
            ))
         )} */}
          {/* <div className="flex justify-center">
            <PaginationPublic
              postPerPage={postPerPage}
              totalPost={MyPesan.length}
              paginate={paginate}
            />
          </div> */}
        </div>
      </section>
      <div className="flex justify-center">
        <Banner />
      </div>
      <div className="flex justify-center">
        <Banner />
      </div>
      <a target="__BLANK" href="https://alimxx.itch.io/secret-chat">
        <button
          type="submit"
          onClick={handleClick}
          className="w-full mt-10 animate-bounce motion-safe:animate-bounce  items-center py-3.5 px-4 text-sm font-medium text-center text-white bg-primary-800 dark:bg-primary-700 rounded-full focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-700 hover:bg-primary-900 dark:hover:bg-primary-500"
        >
          Dapatkan Pesan Untukmu Sendiri
        </button>
      </a>
      <footer className="p-4 relative z-10">
        <div className="md:flex md:justify-between">
          <div className="mb-8 mr-3 md:mb-0">
            <a href="#" className="flex items-center">
              <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                Secret Chat
              </span>
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Follow us
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4">
                  <a
                    href="https://github.com/Whyriez"
                    className="hover:underline "
                  >
                    Github
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://discord.gg/4eeurUVvTy"
                    className="hover:underline"
                  >
                    Discord
                  </a>
                </li> */}
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Legal
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4">
                  <a href="#" className="hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline">
                    Terms &amp; Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2023 <a className="hover:underline">Alimsuma</a>. All Rights
            Reserved.
          </span>
          <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <a
              href="https://www.instagram.com/alimsuma/"
              target="_blank"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Instagram page</span>
            </a>
            <a
              href="https://twitter.com/Alimmxx12"
              target="_blank"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
              <span className="sr-only">Twitter page</span>
            </a>
            <a
              href="https://github.com/Whyriez"
              target="_blank"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">GitHub account</span>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UserPesan;
