import React from "react";
import About from "../components/Pagination/About";
import LayoutMain from "./LayoutMain";

const MyAbout = () => {
  return (
    <LayoutMain>
      <div className="justify-center mx-auto">
        <About />
      </div>
    </LayoutMain>
  );
};

export default MyAbout;
