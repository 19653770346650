import React from "react";
import LihatDetailUser from "../components/LihatDetailUser";
import LayoutMain from "./LayoutMain";

const DetailUser = () => {
  return (
    <LayoutMain>
      <div className="justify-center mx-auto">
        <LihatDetailUser />
      </div>
    </LayoutMain>
  );
};

export default DetailUser;
