import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";

const Welcome = () => {
  const { user } = useSelector((state) => state.auth);

  const [udH, setUdH] = useState(false);
  const [copy, setCopy] = useState(false);
  const [id, setId] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    let hei = user && user.uuid;
    setId(hei);
  }, [user]);

  useEffect(() => {
    axios
      .get(`https://tame-gold-barnacle-tie.cyclic.app/user_header/${id}`)
      .then((result) => {
        setData(result.data);
      });
  }, []);

  useEffect(() => {
    if (id) {
      if (data?.length) {
        datasss();
      }
    }
  }, [data, id]);

  const datasss = () => {
    let found = data.find((dat) => dat.uuid === id);

    if (!found) return setUdH(false);
    setUdH(true);
  };

  // const getData = async () => {
  //   const response = await axios.get(
  //     `https://tame-gold-barnacle-tie.cyclic.app/user_header/${id}`
  //   );
  //   console.log(response);
  // };

  // const getUd = async () => {
  //   const res = await axios.get(`https://tame-gold-barnacle-tie.cyclic.app/user_header/${id}`);
  //   setData(res.data);
  // };

  const deleteHeader = () => {
    axios.all([
      axios.delete(
        `https://tame-gold-barnacle-tie.cyclic.app/user_header/${id}`
      ),
      axios.delete(
        `https://tame-gold-barnacle-tie.cyclic.app/pesan-saya/${id}`
      ),
      axios.delete(
        `https://tame-gold-barnacle-tie.cyclic.app/komentar-pesan/${id}`
      ),
    ]);
    window.location.reload(true);
  };

  return (
    <div>
      <section className="py-8 mb-8 lg:py-16 text-center">
        <h1 className="font-mono text-gray-900 dark:text-white font-bold text-4xl">
          Dashboard
        </h1>
        <h2 className="text-2xl text-gray-900 dark:text-white">
          Selamat Datang {user && user.name}
        </h2>
      </section>

      {udH ? (
        <section className="justify-center mx-auto">
          <div className="w-full  py-2 px-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <article
              className=" mb-6 text-base bg-white rounded-lg dark:bg-gray-900 box-border h-auto w-full p-4 
                border-2 dark:border-gray-600 border-rounded "
            >
              <h1 className="font-mono text-gray-900 dark:text-white text-center font-bold text-2xl mb-4">
                Make this your Whatsapp / Instagram status.
              </h1>
              <form>
                <div className="flex">
                  <div className="w-full  py-2 px-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                    <input
                      id="comment"
                      rows="6"
                      className=" px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-gray-400 dark:placeholder-gray-400 dark:bg-gray-800"
                      placeholder="Write a comment..."
                      defaultValue={`https://secret-chat.limapp.my.id/${
                        user && user.name
                      }`}
                      required
                    />
                  </div>

                  <CopyToClipboard
                    text={`https://secret-chat.limapp.my.id/${
                      user && user.name
                    }`}
                    onCopy={() => setCopy(true)}
                  >
                    <button className="ml-4 py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
                      {copy ? "Salin Berhasil" : "Salin"}
                    </button>
                  </CopyToClipboard>
                </div>
              </form>

              <form onSubmit={deleteHeader}>
                <button
                  className="w-full mt-4 py-2.5 px-4 text-xs font-medium text-center text-white bg-red-700 rounded-lg focus:ring-4 focus:ring-red-200 dark:focus:ring-red-900 hover:bg-red-800"
                  type="submit"
                >
                  Hapus
                </button>
              </form>
            </article>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Welcome;
