import React, { useEffect, useState } from "react";
import FormLogin from "../components/FormLogin";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getMe } from "../features/authSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [me, setMe] = useState("");

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!me) dispatch(getMe());
  }, [me]);

  useEffect(() => {
    if (user) {
      getME();
    }
  }, [me]);

  const getME = async () => {
    const response = await axios.get(
      "https://tame-gold-barnacle-tie.cyclic.app/me"
    );
    setMe(response.data);
  };

  useEffect(() => {
    if (me) {
      navigate("/dashboard");
    }
  }, [me]);

  return <FormLogin />;
  // useEffect(() => {
  //   if (user) {
  //     navigate("/dashboard");
  //   }
  // }, [user, navigate]);
};

export default Login;
