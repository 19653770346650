import React, { useEffect } from "react";
import LayoutMain from "./LayoutMain";
import Welcome from "../components/Welcome";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";
import PesanUserList from "../components/PesanUserList";

const PesanUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/login");
    }
  }, [isError, navigate]);
  return (
    <LayoutMain>
      <div className="justify-center mx-auto">
        <PesanUserList />
      </div>
    </LayoutMain>
  );
};

export default PesanUser;
