import React from "react";
import {
  HashRouter,
  Switch,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Home from "./pages/Home.jsx";
import ChatPublic from "./pages/ChatPublic.jsx";
import Users from "./pages/Users.jsx";
import AddUsers from "./pages/AddUsers.jsx";
import EditUsers from "./pages/EditUsers.jsx";
import Login from "./pages/Login.jsx";
import Register from "./pages/Register.jsx";
import PesanUser from "./pages/PesanUser.jsx";
import UserPesanSaya from "./pages/UserPesanSaya.jsx";
import DetailPublic from "./pages/DetailPublic.jsx";
import DetailUser from "./pages/DetailUser.jsx";
import Errors from "./components/Pagination/Error.jsx";
import AdPublic from "./pages/AdPublic.jsx";
import AllUserPesan from "./pages/AllUserPesan.jsx";
import MyAbout from "./pages/MyAbout.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Send from "./components/Send.jsx";
import Privacy from "./pages/Privacy.jsx";

function App() {
  // const logged = window.localStorage.getItem("islogin");

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-300 dark:bg-gray-900">
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Errors />} />
          <Route path="/" element={<ChatPublic />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/add" element={<AddUsers />} />
          <Route path="/users/edit/:id" element={<EditUsers />} />
          <Route path="/pesan-saya" element={<PesanUser />} />
          <Route path="/:id" element={<UserPesanSaya />} />
          {/* <Route path="/pesan-saya/:id" element={<UserPesanSaya />} /> */}
          <Route path="/detail-public/:id" element={<DetailPublic />} />
          {/* <Route path="/pesan-saya/:id/:id" element={<DetailUser />} /> */}
          <Route path="/:id/:id" element={<DetailUser />} />
          <Route path="/all-public" element={<AdPublic />} />
          <Route path="/pesan-user" element={<AllUserPesan />} />
          <Route path="/error" element={<Errors />} />
          <Route path="/about" element={<MyAbout />} />
          <Route path="/send" element={<Send />} />
          <Route path="/privacy/:id" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
