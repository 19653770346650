import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const AdminPesanUser = () => {
  const [pesan, setPesan] = useState([]);

  const getPesan = async () => {
    const response = await axios.get(
      "https://tame-gold-barnacle-tie.cyclic.app/pesan-saya"
    );
    setPesan(response.data);
  };

  useEffect(() => {
    getPesan();
  }, []);

  //   const deleteTanggapan = async (usersId) => {
  //     await axios.delete(
  //       `https://tame-gold-barnacle-tie.cyclic.app/tanggapan/${usersId}`
  //     );
  //     getTanggapan();
  //   };

  return (
    <div>
      <div>
        <section className="py-8 md:mb-8 lg:py-16">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    No
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nama
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Pesan
                  </th>
                  {/* <th scope="col" className="px-6 py-3">
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {pesan.map((pes, index) => (
                  <tr
                    className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    key={pes.id}
                  >
                    <td className="px-6 py-4">{index + 1}</td>
                    <td className="px-6 py-4">{pes.name}</td>
                    <td className="px-6 py-4">{pes.text}</td>
                    {/* <td className="px-6 py-4">
                      <Link
                        to={`/users/edit/`}
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-3"
                      >
                        Edit
                      </Link>
                      <button
                        // onClick={() => deleteTanggapan(pes.id)}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline"
                      >
                        Delete
                      </button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AdminPesanUser;
