import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const response = await axios.get(
      "https://tame-gold-barnacle-tie.cyclic.app/users"
    );
    setUsers(response.data);
  };

  const deleteUsers = async (usersId) => {
    await axios.delete(
      `https://tame-gold-barnacle-tie.cyclic.app/users/${usersId}`
    );
    getUsers();
  };

  return (
    <section className=" py-8 md:mb-8 lg:py-16">
      <div className=" mb-9 mt-5">
        <Link
          to={"/users/add"}
          className="mb-6 items-center py-3.5 px-4 text-xs font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
        >
          Add New Users
        </Link>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-16 py-5">
                No
              </th>
              <th scope="col" className="px-16 py-5">
                Name
              </th>
              <th scope="col" className="px-16 py-5">
                Email
              </th>
              <th scope="col" className="px-16 py-5">
                Role
              </th>
              <th scope="col" className="px-16 py-5">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr
                className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                key={user.uuid}
              >
                <td className="px-16 py-4">{index + 1}</td>
                <th
                  scope="row"
                  className="px-16 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {user.name}
                </th>
                <td className="px-16 py-4">{user.email}</td>
                <td className="px-16 py-4">{user.role}</td>
                <td className="px-16 py-4">
                  <Link
                    to={`/users/edit/${user.uuid}`}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-3"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => deleteUsers(user.uuid)}
                    className="font-medium text-red-600 dark:text-red-500 hover:underline"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default UserList;
