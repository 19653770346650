import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LihatDetailPublic from "../components/LihatDetailPublic";
import LayoutMain from "./LayoutMain";

const DetailPublic = () => {
  // const [data, setData] = useState([]);
  // const [dapat, setDapat] = useState(false);

  // const navigate = useNavigate();
  // const { id } = useParams();

  // const getTanggapan = async () => {
  //   const response = await axios.get(`http://localhost:5000/tanggapan/${id}`);
  //   // console.log(response.data.id);
  //   setData(response.data);
  //   setDapat(true);
  // };

  // const getUd = async () => {
  //   const res = await axios.get(`http://localhost:5000/user_header/${id}`);
  //   c
  //   setDapat(true);
  // };

  // useEffect(() => {
  //   getTanggapan();
  // }, []);

  // useEffect(() => {
  //   cari();
  // }, [data]);

  // const cari = () => {
  //   if (dapat) {
  //     const ed = data?.id;
  //     console.log(ed);
  //     if (ed == id) {
  //       console.log("benar");
  //     } else {
  //       navigate("/error");
  //     }
  //     if (data.length === 0) {
  //       console.log("bessnar");
  //     }
  //   }
  // };

  return (
    <LayoutMain>
      <div className="justify-center mx-auto">
        <LihatDetailPublic />
      </div>
    </LayoutMain>
  );
};

export default DetailPublic;
