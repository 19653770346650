import React, { useEffect, useRef } from "react";

const BannerAd300x250 = () => {
  const bannerRef = useRef(null);

  const atOptions = {
    key: "cdb819392d2a0388adcb657f83621b39",
    format: "iframe",
    height: 250,
    width: 300,
    params: {},
  };

  useEffect(() => {
    const { current: bannerElement } = bannerRef;

    if (bannerElement && !bannerElement.firstChild) {
      const conf = document.createElement("script");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

      bannerElement.appendChild(conf);
      bannerElement.appendChild(script);
    }
  }, [bannerRef]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={bannerRef}
    ></div>
  );
};

export default BannerAd300x250;
