import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Logout, reset } from "../features/authSlice";
import { FaSun, FaMoon } from "react-icons/fa";
import style from "../assets/hamburger.module.css";

const Navigation = ({ children }) => {
  const [navbar, setNavbar] = useState(false);
  const [burger_class, setBurgerClass] = useState("burger_bar unclicked");

  const updateMenu = () => {
    if (!navbar) {
      setBurgerClass("burger_bar clicked");
    } else {
      setBurgerClass("burger_bar unclicked");
    }
  };

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "system"
  );

  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  darkQuery.addEventListener("change", (e) => {
    if (!("theme" in localStorage)) {
      if (e.matches) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    }
  });

  const handlerTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const logout = () => {
    dispatch(Logout());
    dispatch(reset());
    window.localStorage.removeItem("islogin");
    navigate("/");
  };
  return (
    <div className="dark:bg-gray-900 bg-gray-300 ">
      <nav className="relative z-10 top-0 w-full shadow bg-gray-400 dark:bg-gray-900 bg-opacity-20 dark:bg-opacity-20">
        <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <a href="/">
                <h2 className="font-sans md:font-serif text-gray-900 dark:text-white subpixel-antialiased text-2xl font-bold">
                  Secret Chat
                </h2>
              </a>
              <div className="md:hidden">
                <button
                  className="p-2 transition-all ease-out duration-500 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 transition-all ease-out duration-500 ${
                navbar ? "top-20" : "hidden"
              }`}
            >
              <ul className="duration-300 ease-out items-center text-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                {user && user.role === "admin" && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <Link to={"/dashboard"}>Dahsboard</Link>
                  </li>
                )}
                {user && user.role === "admin" && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <Link to={"/users"}>Users</Link>
                  </li>
                )}
                {user && user.role === "admin" && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <Link to={"/all-public"}>Pesan Public</Link>
                  </li>
                )}
                {user && user.role === "admin" && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <Link to={"/pesan-user"}>Pesan User</Link>
                  </li>
                )}
                {user && user.role === "admin" && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <button onClick={logout}>Logout</button>
                  </li>
                )}
                {user && user.role === "user" && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <Link to={"/dashboard"}>Dashboard</Link>
                  </li>
                )}
                {user && user.role === "user" && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <Link to={"/pesan-saya"}>Pesan Saya</Link>
                  </li>
                )}
                {user && user.role === "user" && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <button onClick={logout}>Logout</button>
                  </li>
                )}
                {!user && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <Link to={"/"}>Home</Link>
                  </li>
                )}
                {!user && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <Link to={"/about"}>About</Link>
                  </li>
                )}

                {!user && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <a
                      target="__BLANK"
                      href="https://alimxx.itch.io/secret-chat"
                    >
                      <button className="w-50  items-center py-3.5 px-4 text-xs font-medium text-center text-white bg-primary-500 dark:bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
                        Get App
                      </button>
                    </a>
                  </li>
                )}
                {/* {!user && (
                  <li className="text-gray-900 dark:text-white hover:text-gray-600">
                    <Link to={"/login"}>Login</Link>
                  </li>
                )} */}
                <div className="flex items-center justify-center  transition duration-500 ease-in-out rounded-full p-2">
                  {theme == "light" ? (
                    <FaSun
                      onClick={handlerTheme}
                      className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
                    />
                  ) : (
                    <FaMoon
                      onClick={handlerTheme}
                      className="text-gray-500 dark:text-gray-400 text-2xl cursor-pointer"
                    />
                  )}
                </div>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
        {children}
      </div>
    </div>
  );
};

export default Navigation;
