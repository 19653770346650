import React, { useState, useEffect } from "react";
import PrivacyPage from "../components/PrivacyPage";
import { useNavigate, useParams } from "react-router-dom";

const Privacy = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id != 2614) {
      navigate(-1);
    }
  });

  return (
    <div className="justify-center mx-auto px-4 lg:max-w-7xl md:items-center md:flex md:px-8">
      <PrivacyPage />
    </div>
  );
};

export default Privacy;
