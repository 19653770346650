import React, { useEffect, useRef } from "react";

const Banner160x600 = () => {
  const bannerRef = useRef(null);

  const atOptions = {
    key: "0a46e9ab68aad0953ee996782ab873b7",
    format: "iframe",
    height: 600,
    width: 160,
    params: {},
  };

  useEffect(() => {
    const { current: bannerElement } = bannerRef;

    if (bannerElement && !bannerElement.firstChild) {
      const conf = document.createElement("script");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

      bannerElement.appendChild(conf);
      bannerElement.appendChild(script);
    }
  }, [bannerRef]);

  return (
    <div
      className="mx-2 my-5 border border-gray-200 justify-center items-center text-white text-center"
      ref={bannerRef}
    ></div>
  );
};

export default Banner160x600;
