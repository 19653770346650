import React, { useEffect } from "react";

const PrivacyPage = () => {
  return (
    <div>
      <div className="bg-gray-100">
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-bold mb-4">Kebijakan Privasi</h1>

          <p className="mb-4">
            Sebagai penyedia layanan produk digital, kami Secret Chat sangat
            menjunjung tinggi privasi user. Hal ini karena informasi pribadi
            merupakan hal yang sangat krusial dan tidak boleh diketahui
            siapapun. Berikut akan kami jelaskan mengenai informasi apa saja
            yang kami terima dan kami kumpulkan pada saat Anda mengunjungi situs
            secret-chat atau menggunakan Aplikasi Secret Chat. Serta, tentang
            bagaimana kami menyimpan dan menjaga informasi tersebut. Kami
            tegaskan bahwa kami tidak akan pernah memberikan informasi tersebut
            kepada siapapun.
          </p>

          <h2 className="text-2xl font-bold mb-2">
            Informasi Yang Dikumpulkan Oleh Secret Chat
          </h2>

          <p className="mb-4">Kami mengambil beberapa informasi berikut:</p>

          <ul className="list-disc list-inside mb-4">
            <li>Username dan email anda</li>
            <li>Password dari email anda</li>
            <li>Pesan yang dikirimkan ke anda</li>
          </ul>

          <h2 className="text-2xl font-bold mb-2">
            Kami Menggunakan Data Untuk Membangun Layanan Yang Lebih Baik
          </h2>

          <p className="mb-4">
            Kami menggunakan informasi Anda untuk mengoperasikan dan menyediakan
            layakan kepada anda. Data yang dikumpulkan oleh situs dan aplikasi
            kami tidak akan di sebarluaskan ke pihak manapun dan murni akan di
            gunakan secara profesional oleh Secret Chat.
          </p>
          <h2 className="text-2xl font-bold mb-2">Privasi Anak-Anak</h2>

          <p className="mb-4">
            Layanan kami tidak dimaksudkan untuk digunakan oleh anak-anak di
            bawah usia 13 tahun. Jika kami mengetahui bahwa kami telah
            mengumpulkan informasi pribadi melalui Layanan dari seorang anak di
            bawah 13 tahun tanpa persetujuan orang tua atau wali anak tersebut
            sebagaimana diwajibkan oleh hukum, kami akan menghapusnya. Jika Anda
            adalah orang tua atau wali dan Anda mengetahui bahwa Anak Anda telah
            memberikan informasi pribadi kepada kami, silakan hubungi kami
            sebagaimana dijelaskan di bagian “Hubungi kami” di bawah. Dalam
            kasus tertentu, usia ini mungkin lebih tinggi dari 13 tahun karena
            persyaratan peraturan setempat.
          </p>

          <h2 className="text-2xl font-bold mb-2">Data Yang Disimpan </h2>

          <ul className="list-disc list-inside mb-4">
            <li>Username dan email anda</li>
            <li>Password dari email anda</li>
            <li>Pesan yang dikirimkan ke anda</li>
          </ul>

          <h2 className="text-2xl font-bold mb-2">Data Yang Dihapus </h2>

          <ul className="list-disc list-inside mb-4">
            <li>Username dan email anda</li>
            <li>Password dari email anda</li>
            <li>Pesan yang dikirimkan ke anda</li>
          </ul>

          <h2 className="text-2xl font-bold mb-2">Hubungi Kami</h2>

          <p className="mb-4">
            Secret Chat adalah entitas yang bertanggung jawab atas pemrosesan
            informasi pribadi Anda, dan merupakan pengontrol data sehubungan
            dengan pemrosesan tersebut. Jika Anda memiliki pertanyaan atau
            komentar tentang Kebijakan Privasi ini, praktik privasi kami, atau
            jika Anda ingin menggunakan hak Anda sehubungan dengan informasi
            pribadi Anda, silakan hubungi kami melalui email di{" "}
            <a
              href="mailto:nursuma004@gmail.com"
              className="text-blue-600 underline"
            >
              nursuma004@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
