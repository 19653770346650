import React from "react";
import style from "../assets/bg.module.css";
import { useState } from "react";
import BannerAd728x90 from "./Banner/BannerAd728x90";

function Send() {
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleButtonClick = () => {
    if (!buttonClicked) {
      setButtonClicked(true);
      window.open(
        "https://www.toprevenuegate.com/qiaxy6ecf?key=79f2c25823186a6e2d166a0e509b2d90",
        "_blank"
      );
    } else {
      window.history.back();
      // javascript: history.back();
    }
  };

  return (
    <div>
      <div className={style.box}>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <section className="py-8 mb-8 lg:py-16 relative z-10">
        <div className="max-w-2xl mx-auto px-4">
          <a href="javascript:history.back()">
            <img
              src={require("../assets/back-button.png")}
              width="35px"
              className="object-center"
              alt=""
            />
          </a>

          <div className="flex justify-center">
            <img
              src={require("../assets/checklist.png")}
              width="100px"
              className="object-center"
              alt=""
            />
          </div>
          <div className=" items-center  mb-3">
            <h2 className="text-center font-medium text-lg lg:text-2xl text-gray-900   dark:text-gray-200">
              Terkirim
            </h2>
          </div>
          {/* 
          <form className="mb-6">
            asasasas
            <p className="pb-2 pl-2 text-sm text-gray-900 dark:text-gray-400">
              asas tidak akan pernah tahu siapa yang mengirim pesan ini!
            </p>
          </form> */}

          <a
            target="__BLANK"
            href="https://drive.google.com/drive/folders/1NbkoMSrATjM8rFmgzzofTR2PE6ShZRpO?usp=sharing"
          >
            <button
              type="submit"
              className="w-full mt-10 animate-bounce motion-safe:animate-bounce  items-center py-3.5 px-4 text-sm font-medium text-center text-white bg-primary-800 dark:bg-primary-700 rounded-full focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-700 hover:bg-primary-900 dark:hover:bg-primary-500"
            >
              Dapatkan Pesan Untukmu Sendiri
            </button>
          </a>

          <div className="flex justify-center">
            <button
              onClick={handleButtonClick}
              className="text-center mt-3 underline text-lg lg:text-2xl font-bold text-gray-900 dark:text-gray-200"
            >
              Kirim pesan lain
            </button>
          </div>
          <BannerAd728x90 />
        </div>
      </section>
    </div>
  );
}

export default Send;
