import React from "react";
import style from "../../assets/bg.module.css";
import BannerAd from "../Banner/Banner160x600";

const About = () => {
  return (
    <div>
      <div className={style.box}>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <section className="py-8 mb-8 lg:py-16 relative z-10">
        <h1 className="mb-3 text-center text-5xl font-light text-gray-500 dark:text-gray-400 first-line:uppercase first-line:tracking-widest first-letter:text-7xl first-letter:font-bold first-letter:text-gray-900 dark:first-letter:text-gray-100 first-letter:mr-3">
          About
        </h1>
        <p className="mb-3 font-light text-gray-500 dark:text-gray-400 first-line:uppercase first-line:tracking-widest first-letter:text-7xl first-letter:font-bold first-letter:text-gray-900 dark:first-letter:text-gray-100 first-letter:mr-3 first-letter:float-left">
          Secret Chat ini merupakan aplikasi berbasis web dengan sistem
          anonymous chat dimana chat tidak dapat diketahui oleh siapapun,
          aplikasi ini terinspirasi dari aplikasi secreto.site dimana secara
          keseluruhan hampir mirip baik ui maupun fungsi, dan aplikasi ini juga
          dibuat khusus untuk pembelajaran mengenai bahasa pemrograman
          javascript dengan frontendnya menggunakan react js dan backendnya
          menggunkan node js. Jika anda mempunya saran masukan mengenai aplikasi
          ini bisa langsung berikan pesan anda di menu home, hal ini sangat
          berperan penting karena akan dijadikan sebagai pembelajaran tambahan
          dalam membuat fitur baru maupun fixing bug yang ada dari saran
          teman-teman.
        </p>
        <p className="font-light text-gray-500 dark:text-gray-400">
          Selamat Mencoba --Alim
        </p>
        <p className="font-light text-gray-500 dark:text-gray-400">
          Trakter saya cendol{" "}
          <a
            href="https://trakteer.id/alimsuma"
            target="__BLANK"
            className="text-blue-800 dark:text-blue-400"
          >
            trakteer.id/alimsuma
          </a>
        </p>

        <BannerAd />
      </section>
    </div>
  );
};

export default About;
