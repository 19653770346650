import React from "react";
import Public from "../components/Public";
import LayoutMain from "./LayoutMain";
// import Partitcle from "../components/Animation/Particle";

const ChatPublic = () => {
  return (
    <LayoutMain>
      <div className="justify-center mx-auto">
        {/* <Partitcle /> */}
        <Public />
      </div>
    </LayoutMain>
  );
};

export default ChatPublic;
