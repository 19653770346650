import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import PaginationPublic from "./Pagination/PaginationPublic";
import style from "../assets/bg.module.css";
import Linkify from "react-linkify";
import BannerAd from "./Banner/BannerAd";
import BannerAd1 from "./Banner/BannerAd300x250";
import Footer from "./Footer";

const Public = () => {
  const [isLoading, setLoading] = useState(true);
  const [isLoadingKomen, setLoadingKomen] = useState(true);
  const [clickedButton, setClickedButton] = useState(0);
  const [animLoad, setAnimLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(5);

  const [text, setTanggapan] = useState("");
  const [tanggapan, setTampilTanggapan] = useState([]);
  const [komenTanggapan, setTampilKomenTanggapan] = useState([]);
  const [pesanPublicId, setIdPesan] = useState("");

  const [textKt, setKomen] = useState("");

  useEffect(() => {
    getTanggapan();
    getKomenTanggapan();
  }, []);

  function handleClick() {
    if (!text.length == 0) {
      setLoading(false);
    }
    if (!textKt.length == 0) {
      setLoadingKomen(false);
    }
  }

  const saveTanggapan = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://tame-gold-barnacle-tie.cyclic.app/tanggapan", {
        text,
      });
      setLoading(true);

      setTanggapan("");
      getTanggapan();
      // window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onGetId = (id) => {
    setIdPesan(id);
  };

  function handleChange(event) {
    event.preventDefault();
    let temp = event.target.value;
    setKomen(temp);
  }

  const saveKomen = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        "https://tame-gold-barnacle-tie.cyclic.app/komentar-tanggapan",
        {
          textKt,
          pesanPublicId,
        }
      );
      setKomen("");
      setLoadingKomen(true);
      e.target.reset();
      getKomenTanggapan();

      // window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTanggapan = async () => {
    const response = await axios.get(
      "https://tame-gold-barnacle-tie.cyclic.app/tanggapan"
    );
    setTampilTanggapan(response.data);
    setAnimLoad(false);
  };
  const getKomenTanggapan = async () => {
    const response = await axios.get(
      `https://tame-gold-barnacle-tie.cyclic.app/komentar-tanggapan`
    );
    setTampilKomenTanggapan(response.data);
  };

  const indexLastPost = currentPage * postPerPage;
  const indexFirstPost = indexLastPost - postPerPage;
  const currentPost = tanggapan.slice(indexFirstPost, indexLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} className="text-blue-900" target="_blank">
      {text}
    </a>
  );

  // let style1 = { "--i": 11 };
  // let style2 = { "--i": 12 };
  // let style3 = { "--i": 24 };
  // let style4 = { "--i": 10 };
  // let style5 = { "--i": 14 };
  // let style6 = { "--i": 23 };
  // let style7 = { "--i": 18 };
  // let style8 = { "--i": 16 };
  // let style9 = { "--i": 19 };
  // let style10 = { "--i": 20 };
  // let style11 = { "--i": 22 };
  // let style12 = { "--i": 25 };
  // let style13 = { "--i": 16 };
  // let style14 = { "--i": 21 };
  // let style15 = { "--i": 15 };
  // let style16 = { "--i": 13 };
  // let style17 = { "--i": 26 };
  // let style18 = { "--i": 17 };
  // let style19 = { "--i": 13 };
  // let style20 = { "--i": 28 };

  return (
    <div>
      {/* <div className={style.bubbles}>
        <span style={style1}></span>
        <span style={style2}></span>
        <span style={style3}></span>
        <span style={style4}></span>
        <span style={style5}></span>
        <span style={style6}></span>
        <span style={style7}></span>
        <span style={style8}></span>
        <span style={style9}></span>
        <span style={style10}></span>
        <span style={style11}></span>
        <span style={style12}></span>
        <span style={style13}></span>
        <span style={style14}></span>
        <span style={style15}></span>
        <span style={style16}></span>
        <span style={style17}></span>
        <span style={style18}></span>
        <span style={style19}></span>
        <span style={style20}></span>
      </div>
      <div className={style.bubbles_red}>
        <span style={style1}></span>
        <span style={style2}></span>
        <span style={style3}></span>
        <span style={style4}></span>
        <span style={style5}></span>
        <span style={style6}></span>
        <span style={style7}></span>
        <span style={style8}></span>
        <span style={style9}></span>
        <span style={style10}></span>
        <span style={style11}></span>
        <span style={style12}></span>
        <span style={style13}></span>
        <span style={style14}></span>
        <span style={style15}></span>
        <span style={style16}></span>
        <span style={style17}></span>
        <span style={style18}></span>
        <span style={style19}></span>
        <span style={style20}></span>
      </div> */}
      <div className={style.box}>
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <section className="relative z-10 py-8 mb-8 lg:py-16">
        <div className="max-w-2xl mx-auto px-4">
          <div className=" items-center mb-6">
            <h2 className="text-center text-lg lg:text-2xl text-gray-900 dark:text-gray-200">
              Berikan Tanggapan Anda Tentang
            </h2>
            <h2 className="text-center text-lg lg:text-2xl font-bold text-gray-900 dark:text-gray-200">
              Aplikasi Ini
            </h2>
          </div>
          <form className="mb-6" onSubmit={saveTanggapan}>
            {/* <h1 className="text-center text-red-500">{error}</h1> */}
            <p className="pb-2 pl-2 text-sm text-gray-900 dark:text-gray-400">
              Public tidak akan pernah tahu siapa yang mengirim pesan ini!
            </p>
            <div className="py-2 px-4 mb-4 bg-gray-200 rounded-lg rounded-t-lg border bg-opacity-75 border-gray-200  dark:bg-opacity-75  dark:bg-gray-800 dark:border-gray-700">
              <label className="sr-only">Your comment</label>
              <textarea
                id="comment"
                rows="6"
                className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none bg-gray-300 bg-opacity-5 dark:text-white  dark:bg-opacity-5 placeholder-gray-900 dark:placeholder-gray-400 dark:bg-gray-800"
                placeholder="Tulis Komentar..."
                value={text}
                onChange={(e) => setTanggapan(e.target.value)}
                required
              ></textarea>
            </div>

            <button
              type="submit"
              onClick={handleClick}
              className="w-full  items-center py-3.5 px-4 text-xs font-medium text-center text-white bg-primary-500 dark:bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
            >
              {!isLoading ? (
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-4 h-4 mr-3 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
              ) : (
                ""
              )}

              {isLoading ? "Kirim Tanggapan" : "Loading..."}
            </button>
          </form>
          <div className="text-gray-900 dark:text-gray-200 text-2xl pt-4 pl-2 pb-4 mb-3">
            Timeline Of <a className="font-bold">Tanggapan</a>
          </div>

          <div className="flex w-full justify-center mb-6 mt-3">
            <BannerAd1 />
          </div>
          <div className="flex justify-center mb-6 mt-3">
            <PaginationPublic
              postPerPage={postPerPage}
              totalPost={tanggapan.length}
              paginate={paginate}
            />
            {/* <BannerAd1 /> */}
          </div>

          {animLoad ? (
            <div role="status" className="max-w animate-pulse">
              <article
                className=" mb-6 text-base bg-white rounded-lg dark:bg-gray-900 box-border w-full p-4 
                border-2 dark:border-gray-600 border-rounded "
              >
                <Link className="text-gray-300 hover:text-gray-500">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[140px] mb-2.5"></div>
                </Link>

                <h1 className="text-gray-500 dark:text-gray-400 mb-3">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                </h1>

                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
              </article>
              <article
                className=" mb-6 text-base bg-white rounded-lg dark:bg-gray-900 box-border w-full p-4 
                border-2 dark:border-gray-600 border-rounded "
              >
                <Link className="text-gray-300 hover:text-gray-500">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[140px] mb-2.5"></div>
                </Link>

                <h1 className="text-gray-500 dark:text-gray-400 mb-3">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                </h1>

                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
              </article>
              <article
                className=" mb-6 text-base bg-white rounded-lg dark:bg-gray-900 box-border w-full p-4 
                border-2 dark:border-gray-600 border-rounded "
              >
                <Link className="text-gray-300 hover:text-gray-500">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[140px] mb-2.5"></div>
                </Link>

                <h1 className="text-gray-500 dark:text-gray-400 mb-3">
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                </h1>

                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
                <article className="pt-3 mb-2 text-base bg-white rounded-lg dark:bg-gray-900">
                  <div className="dark:bg-gray-800 rounded-lg w-full py-2.5 px-4 mb-2">
                    <h1 className="text-gray-500 dark:text-gray-400">
                      <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                    </h1>
                  </div>
                </article>
              </article>
            </div>
          ) : (
            currentPost
              .sort((a, b) => (a.id < b.id ? 1 : -1))
              .map((pes, index) => (
                <div
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  key={pes.uuid}
                >
                  <article
                    className=" mb-6 text-base bg-gray-200 rounded-lg dark:bg-gray-900 box-border h-auto w-full p-4 
                border-2 dark:border-gray-600 border-rounded bg-opacity-75 dark:bg-opacity-80"
                    onClick={() => onGetId(pes.id)}
                  >
                    <Link
                      to={`detail-public/${pes.id}`}
                      className="text-gray-800 dark:text-gray-300 hover:text-gray-500"
                    >
                      Lihat Detail
                    </Link>

                    <Linkify componentDecorator={componentDecorator}>
                      <p className="text-gray-800 text-clip break-words  dark:text-gray-400 mb-3">
                        {pes.text}
                      </p>
                    </Linkify>
                    <div
                      className="mb-3"
                      onClick={() => onGetId.bind(pes.id)}
                      key={pes.id}
                    >
                      <form onSubmit={saveKomen}>
                        <div className="flex">
                          <div className="w-full  py-2 px-4 bg-black rounded-lg rounded-t-lg border border-gray-300 bg-opacity-5 dark:bg-opacity-5 dark:bg-gray-300 dark:border-gray-700">
                            <input
                              id="comment"
                              rows="6"
                              className=" px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none bg-white bg-opacity-5 dark:text-white dark:bg-opacity-5 placeholder-gray-900 dark:placeholder-gray-400 dark:bg-gray-800"
                              placeholder="Tulis Komentar..."
                              name="komen"
                              // value={clearKomen}
                              onChange={(e) => handleChange(e)}
                              // value={textKt}
                              autoComplete="off"
                              // onChange={(e) => setKomen(e.target.value)}
                              required
                            />
                          </div>

                          <button
                            type="submit"
                            onClick={() => {
                              handleClick();
                              setClickedButton(index);
                            }}
                            key={index}
                            className="ml-4 py-2.5 px-4 text-xs font-medium text-center text-white bg-primary-500 dark:bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
                          >
                            {!isLoadingKomen && clickedButton == index ? (
                              <svg
                                aria-hidden="true"
                                className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-400 fill-blue-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            ) : (
                              "Kirim"
                            )}

                            <span className="sr-only">Loading...</span>
                            {/* Kirim */}
                          </button>
                        </div>
                      </form>
                      {/* <AddKomenPublic
                      postclicked={() => onGetId.bind(pes.id)}
                      komenTanggapan={komenTanggapan}
                      hey={pesanPublicId}
                    /> */}
                    </div>

                    {komenTanggapan
                      .filter((dt) => dt.pesanPublicId === pes.id)
                      .map((komen) => (
                        <div data-aos="fade-down" key={komen.id}>
                          <article className=" mb-2 text-base bg-gray-400 rounded-lg bg-opacity-30 dark:bg-opacity-50 dark:bg-gray-800">
                            <div className="bg-gray-900 bg-opacity-5 dark:bg-opacity-5 dark:bg-gray-200 rounded-lg w-full py-2.5 px-4 mb-2">
                              <Linkify componentDecorator={componentDecorator}>
                                <p className="text-gray-900 break-words dark:text-gray-400">
                                  {komen.textKt}
                                </p>
                              </Linkify>
                            </div>
                          </article>
                        </div>
                      ))}
                  </article>
                </div>
              ))
          )}

          <div className="flex justify-center">
            <PaginationPublic
              postPerPage={postPerPage}
              totalPost={tanggapan.length}
              paginate={paginate}
            />
          </div>
          <BannerAd />
          {/* <Helmet>
          <script type="text/javascript">
      atOptions = {
        key: "f120305c0b89837348f90b048d989867",
        format: "iframe",
        height: 50,
        width: 320,
        params: {},
      };
      document.write(
        "<scr" +
          'ipt type="text/javascript" src="//www.topcreativeformat.com/f120305c0b89837348f90b048d989867/invoke.js"></scr' +
          "ipt>"
      );
    </script>
          </Helmet> */}
          {/* <Helmet>
            <script type="text/javascript">
              {`
            var atOptions = {
              'key': 'f120305c0b89837348f90b048d989867',
              'format': 'iframe',
              'height': 50,
              'width': 320,
              'params': {}
            };
            document.write('<scr' + 'ipt type="text/javascript" src="//www.topcreativeformat.com/f120305c0b89837348f90b048d989867/invoke.js"></scr' + 'ipt>');
          `}
            </script>
          </Helmet> */}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Public;
